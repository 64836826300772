import React, { useEffect, useState } from 'react'
import './home.scss'
import { FaPerson } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import Swal from 'sweetalert2';
import product from "../Comp/Seetechimages/Seetechimages/product.webp"
import Quality from "../Comp/Seetechimages/Seetechimages/Genuine.webp"
import global from "../Comp/Seetechimages/Seetechimages/global Reach.webp"
import support from "../Comp/Seetechimages/Seetechimages/support.webp"
import pricing from "../Comp/Seetechimages/Seetechimages/pricing.webp"
import experienced from "../Comp/Seetechimages/Seetechimages/exp.webp"
import cat from "../Comp/Seetechimages/Seetechimages/cat.webp"
import cased from "../Comp/Seetechimages/Seetechimages/case.webp"
import terex from "../Comp/Seetechimages/Seetechimages/terex.webp"
import jcbimg from "../Comp/Seetechimages/Seetechimages/terex.webp"
import { Helmet } from 'react-helmet-async';



const Home = () => {

    const [searchvalue, setsearchvalue] = useState('');
    const [expandfilters, setfilters] = useState(false)
    const [DataFound, setDataFound] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [Filterdatavalue, setFilterdatavalue] = useState([]);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState({});
    const [checkmodal, setcheckmodal] = useState({});
    const [category, setcategoryfilter] = useState({});
    const [modalcategory, setmodalcategory] = useState([]);
    const [modeldata, setmodeldata] = useState(false);
    const [Cartquantity, setCartquantity] = useState([]);
    const [WishlistItem, setWishlistItem] = useState([]);
    const [CartRecID, setCartRecID] = useState([]);
    const [Totalvalue, setTotalvalue] = useState(0);
    const [productsdata, setproducts] = useState([]);
    const [Totalpages, setTotalpages] = useState('')
    const [CurrentPage, setCurrentPage] = useState(1)
    const [ActivePages, setActivePages] = useState(1)
    const [username, setusername] = useState('');
    const [usertype, setusertype] = useState('');
    const [showsignup, setshowsignup] = useState(false);
    const [windowidth, setwindowwidth] = useState(window.innerWidth);

    const navigate = useNavigate()

    const dispatch = useDispatch();
    // const [windowidth, setwindowwidth] = useState(window.innerWidth);


    useEffect(() => {
        dispatch({ type: 'SaveRoutes', payload: { route: "hompage" } });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const handleWindowsize = () => {
            // setwindowwidth(window.innerWidth);
        }
        window.addEventListener('resize', handleWindowsize);
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        return () => {
            window.removeEventListener('resize', handleWindowsize);
        }
    }, [])


    useEffect(() => {
        if (sessionStorage.getItem('Apipathurl') == undefined || sessionStorage.getItem('Apipathurl') == null || sessionStorage.getItem('Apipathurl') == "") {
            window.location.reload();
        }
        if (sessionStorage.getItem('userid')) {
            // setshowsignup(true);
            let obj1 = {
                'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj1).then((res) => {
                console.log(res[""])
                dispatch({ type: 'CartItems', payload: { CartItems: res[''][0].totalcartitem } });
                setusername(res[""][0].username)
                setusertype(res[""][0].usertype);
                console.log(usertype,)
                if (res[""][0].usertype == "Admin"){
                    setshowsignup(true);
                }
            })
        }
    }, [])


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


   

    return (
        <>
            {/* home root start */}
            <Helmet>
        <title>JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad</title>
        <meta name="description" content="Welcome to Seetech Parts. Explore our range of products, services, and more." />
        <link rel="canonical" href="https://seetechparts.com/" />
      </Helmet>

            <div className="home-root">

                {/* <div className='header-searchbar-root'> */}
                    {/* <div className='header-searchbar-inputdiv'>
                        <input id='searchinput' type='text' placeholder='Search....' value={searchvalue} onKeyDown={handleKeyDown} onChange={(e) => { setsearchvalue(e.target.value) }} style={{ border: 'none', outline: 'none' }}></input>
                        <SearchIcon style={{ color: '#f9b101' }} onClick={() => { storeSearch() }} />
                        {
                            searchvalue.length > 0 ?
                                <CloseIcon style={{ color: '#f9b101' }} onClick={() => { clearSearch(); setDataFound(true) }} />
                                :
                                null
                        }
                    </div> */}
                    {/* {

                        windowidth < 1067 ?
                            <span onClick={() => { setfilters(!expandfilters) }} >
                                <FilterAltIcon style={{ color: "#f9b101" }} />
                            </span>
                            :
                            null
                    } */}
                {/* </div> */}

                {/* home sec-1 start */}
                <div className="home-sec-1">
                    <div className="container">
                        {/* <h1>WELCOME TO SEETECH PARTS</h1>
                        <h4> YOUR ONE STOP INDIA'S BIGGEST E-COMMERCE SOURCING PARTNER FOR HEAVY MACHINERY SPARE PARTS.</h4> */}

                        <h1><div className='border-div'></div>INDIA'S BIGGEST E-COMMERCE</h1>
                        <h1>FOR HEAVY MACHINERY PARTS</h1>
                    </div>
                </div>
                {/* home sec-1 end */}

                {/* home sec-2 start */}

                <div className="home-sec-2">
                    <div className="container">
                        <p> At Seetech Parts, we take pride in being a trusted global supplier of high-quality spare parts for a wide range of heavy machinery brands, including industry giants such as JCB, L&T, Case, Terex, Escorts, CAT, and many more. With a commitment to excellence, we strive to meet the diverse needs of our clients in the construction, mining, and infrastructure sectors.</p>
                    </div>
                </div>

                {/* home sec-2 end */}

                {/* home sec-3 start */}

                <div className="home-sec-3">
                    <div className="container">
                        <h1>WHY CHOOSE SEETECH PARTS</h1>
                        <ul>
                            <li>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '3rem' }} ><img src={product} alt="" width='100%' /></div>
                                    <h2>Extensive Product Range :-</h2>
                                </div>

                                <p>Explore our comprehensive inventory of spare parts designed to cater to the demands of various heavy machinery brands. From excavators and loaders to bulldozers and backhoes, we've got you covered.</p>
                            </li>
                            <li>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '3rem' }} ><img src={Quality} alt="" width='100%' /></div>
                                    <h2>Genuine Quality :-</h2>
                                </div>

                                <p>We understand the critical role that spare parts play in ensuring the seamless operation of heavy machinery. That's why we source only genuine and top-quality components, guaranteeing durability and optimal performance.</p>
                            </li>
                            <li>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '3rem' }} ><img src={global} alt="" width='100%' /></div>
                                    <h2>Global Reach :-</h2>
                                </div>

                                <p>Seetech Parts proudly serves clients worldwide. With a robust logistics network, we ensure timely delivery of your orders, regardless of your location. Our global reach makes us your go-to partner for heavy machinery spare parts.</p>
                            </li>
                            <li>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '3rem' }} ><img src={support} alt="" width='100%' /></div>
                                    <h2>Expert Support :-</h2>
                                </div>
                                <p>Our team of experienced professionals is dedicated to providing expert advice and support. Whether you need assistance in identifying the right part or require technical guidance, we are here to help you every step of the way.</p>
                            </li>
                            <li>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '3rem' }} ><img src={pricing} alt="" width='100%' /></div>
                                    <h2>Competitive Pricing :-</h2>
                                </div>

                                <p>We believe in offering cost-effective solutions without compromising on quality. Our competitive pricing ensures that you get the best value for your investment, helping you keep your heavy machinery running efficiently.</p>
                            </li>

                        </ul>
                    </div>
                </div>

                {/* home sec-3 end */}

                {/* home sec-4 start */}


                <div className='home-sec-4'>
                    <div className='container'>

                        <div className="crane-heading">
                            <h1>SPARE PARTS WE DEAL IN</h1>
                        </div>


                        <div className="crane-body">
                            <div className="crane-div col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <Link to='/Shop/' type='a'>
                                    <img src={jcbimg} alt="" />
                                    <h6>JCB REPLACEMENT PARTS</h6>
                                </Link>
                            </div>

                            <div className="crane-div col-lg-3 col-md-4 ncol-sm-6 col-xs-12">
                                <Link to='/Shop/' type='a'>
                                    <img src={terex} alt="" />
                                    <h6>TEREX REPLACEMENT PARTS</h6>
                                </Link>
                            </div>

                            <div className="crane-div col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <Link to='/Shop/' type='a'>
                                    <img src={cased} alt="" />
                                    <h6>CASE REPLACEMENT PARTS</h6>
                                </Link>
                            </div>

                            <div className="crane-div col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                <Link to='/Shop/' type='a'>
                                    <img src={cat} alt="" />
                                    <h6>CAT REPLACEMENT PARTS</h6>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>

                {/* home sec-4 end */}

                {/* home sec-5 start */}

                <div className="home-sec-5">
                    <div className="container">
                        <div className="exp-div-1 col-lg-4 col-md-4 col-sm-12">
                            <div className="exp-img-div">
                                <img src={experienced} alt="" />
                            </div>
                        </div>
                        <div className="exp-div-2 col-lg-4 col-md-4 col-sm-12">
                            <p>  In 2008, we embarked on this journey when our promoters recognized the challenges faced by heavy machine owners due to the of availability and poor quality of spare parts. Since then, we have worked closely with our customers to fulfil all their spare parts requirements and offer them the necessary support.</p>
                        </div>
                    </div>
                </div>

                {/* home sec-5 end */}

                {/* home sec-6 start */}

                <div className="home-sec-6">
                    <div className="container">
                        <h1>WHAT PEOPLE SAY ?</h1>
                        <div className="slider-container">
                            <Slider {...settings}>

                                <div className="review">
                                    <div className="review-head">
                                        <div className="review-icon">
                                            <FaPerson className='person-icon' />
                                        </div>
                                        <div className="review-name">
                                            <h1>Ravi Sharma</h1>
                                            <p>Fleet Operation Director</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>As a fleet manager, downtime is a costly concern. Seetech has been instrumental in minimizing our equipment downtime by providing high-quality spare parts promptly. The range of brands they cover is impressive, and their commitment to genuine parts ensures our machines run smoothly. The competitive pricing is an added bonus, allowing us to stay within budget without compromising on quality. Seetech is undoubtedly a reliable partner in the heavy machinery industry.</p>
                                    </div>
                                </div>

                                <div className="review">
                                    <div className="review-head">
                                        <div className="review-icon">
                                            <FaPerson className='person-icon' />
                                        </div>
                                        <div className="review-name">
                                            <h1>Abhinav Bindra</h1>
                                            <p>Office Manager</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>Seetech Parts has consistently demonstrated a deep understanding of our industry, and their extensive inventory has proven to be a lifesaver for our operations. The efficiency with which they handle orders and the quick turnaround time have greatly contributed to minimizing downtime.
                                            The spare parts we have received are of the highest standard, and we have noticed a significant improvement in the performance and longevity of our equipment.</p>
                                    </div>
                                </div>

                                <div className="review">
                                    <div className="review-head">
                                        <div className="review-icon">
                                            <FaPerson className='person-icon' />
                                        </div>
                                        <div className="review-name">
                                            <h1>Rakesh R. Trivedi</h1>
                                            <p>Operation's & Logistics Head</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>One of the standout features of Seetech Parts is their commitment to quality. The spare parts supplied by Seetech Parts have consistently met or exceeded industry standards, contributing to the longevity and reliability of our heavy machinery. This has not only enhanced the performance of our equipment but has also resulted in significant cost savings through reduced downtime and maintenance expenses.</p>
                                    </div>
                                </div>

                                <div className="review">
                                    <div className="review-head">
                                        <div className="review-icon">
                                            <FaPerson className='person-icon' />
                                        </div>
                                        <div className="review-name">
                                            <h1>Aakash Verma</h1>
                                            <p>Operation's Director</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>My experience with Seetech Parts has been nothing short of outstanding. As the Operations Director at ABC Manufacturing, finding a reliable source for heavy machinery spare parts is crucial to our day-to-day operations. Seetech Parts not only met but exceeded our expectations.
                                            The range and availability of parts in their inventory are impressive, ensuring that we get what we need promptly.
                                        </p>
                                    </div>
                                </div>

                                <div className="review">
                                    <div className="review-head">
                                        <div className="review-icon">
                                            <FaPerson className='person-icon' />
                                        </div>
                                        <div className="review-name">
                                            <h1>Vivek S.</h1>
                                            <p>Minning Operation's Manager</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>Seetech is a game-changer in the heavy machinery spare parts industry. Their website is user-friendly, making it easy to find the specific components I require for my fleet. The reliability and durability of the parts I've purchased have exceeded my expectations. It's refreshing to work with a company that values quality and customer service. Seetech has earned my trust, and I will continue to choose them for all my heavy machinery needs.</p>
                                    </div>
                                </div>

                                <div className="review">
                                    <div className="review-head">
                                        <div className="review-icon">
                                            <FaPerson className='person-icon' />
                                        </div>
                                        <div className="review-name">
                                            <h1>Karan Rajput</h1>
                                            <p>Construction's Contractor</p>
                                        </div>
                                    </div>
                                    <div className="review-body">
                                        <p>I have been relying on Seetech for heavy machinery spare parts for years, and their service has been nothing short of exceptional. The quality of the parts is top-notch, and their extensive inventory means I can always find what I need. What sets Seetech apart is their commitment to customer satisfaction â€“ the support team is knowledgeable and always ready to assist. Fast shipping and fair prices make them my go-to choice for heavy machinery spare parts. Highly recommended!</p>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>


                {/* home sec-6 end */}

            </div>
            {/* home root end */}
        </>
    )
}

export default Home